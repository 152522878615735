import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { FormControl } from "@angular/forms";
import wcc from "@walletconnect/client";
import QRCodeModal from "@walletconnect/qrcode-modal";
declare var window: any;
declare var Web3: any;

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexTooltip,
  ApexFill,
  ApexLegend,
  ApexTheme,
} from "ng-apexcharts";

export type ChartOptions = {

  theme: ApexTheme;
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

export type ChartOptions2 = {
  theme: ApexTheme;
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  showconnectbtn: boolean = false;
  isLoadingPopUp: boolean = false;
  isLoadingPopUpWalletPopUp: boolean = true;
  modalTextOne = 'Sometimes Metamask doesn’t open pop up window and you will need to open Metamask manually and check if there’s any pending signature.';
  modalTextWallet = 'Sometimes WalletConnect doesn’t open pop up window and you will need to open WalletConnect manually and check if there’s any pending signature.';
  loginMsgModal: boolean = false;
  modalText = '';
  errorMsgModal = false;
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;
  @ViewChild("chart") chart1: ChartComponent;
  public chartOptions2: Partial<ChartOptions2>;
  constructor(private router: Router) {
    //last value 19-01-2021 new update by furkan sheikh
    // series: [
    //   {
    //     name: "Circulating supply",
    //     data: [47328775],
    //   },
    //   {
    //     name: "Locked Tokens",
    //     data: [147178142],
    //   },
    //   {
    //     name: "Staked Tokens",
    //     data: [77565526],
    //   },
    //   {
    //     name: "Foundation Expenses Token",
    //     data: [19388565],
    //   },
    //   {
    //     name: "EarnDrop Token",
    //     data: [8538992],
    //   },
    // ],


    //prev 

    // Circulating supply   47,328,775
    // Locked tokens   142,260,234
    // Multisig wallet   19,388,565
    // Earndrop wallet   8,538,992
    // staked amount   82,483,434
    
    // total amount - 300,000,000

    this.chartOptions = {
      series: [
        {
          name: "Circulating supply",
          data: [47328775],
        },
        {
          name: "Locked Tokens",
          data: [142260234],
        },
        {
          name: "Staked Tokens",
          data: [82483434],
        },
        {
          name: "Foundation Expenses Token",
          data: [19388565],
        },
        {
          name: "EarnDrop Token",
          data: [8538992],
        },
      ],
      chart: {
        type: "bar",
        height: 200,
        stacked: true,
        fontFamily: 'Work Sans, sans-serif',
        stackType: '100%',
        toolbar: {
          show: false
        },
      },
      tooltip: {
        enabled: true,
        marker: {
          show: false,
        },
        custom: ({ series, seriesIndex, dataPointIndex, w }) => {
          return (
            '<div style="color:black" class="arrow_box">'
            + this.numberWithCommas(series[seriesIndex][dataPointIndex])+
            "</div>"
          );
        }
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      xaxis: {
        type: "category",
        categories: [],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [
              "white",
              "white",
              "white",
              "white",
              "white",
              "white",
            ],
            fontSize: "12px",
            fontFamily: 'Work Sans, sans-serif',
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
          offsetX: 0,
          offsetY: 0,
          format: undefined,
          formatter: undefined,
          datetimeUTC: true,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
          },
        },
        axisBorder: {
          show: true,
          color: "#78909C",
          // height: 1,
          // width: "100%",
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          borderType: "solid",
          color: "#78909C",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        tickAmount: undefined,
        tickPlacement: "between",
        min: undefined,
        max: undefined,
        range: undefined,
        floating: false,
        position: "bottom",
        title: {
          text: undefined,
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: "12px",
            fontFamily: 'Work Sans, sans-serif',
            fontWeight: 600,
            cssClass: "apexcharts-xaxis-title",
          },
        },
        crosshairs: {
          show: true,
          width: 1,
          position: "back",
          opacity: 0.9,
          stroke: {
            color: "#b6b6b6",
            width: 0,
            dashArray: 0,
          },
          fill: {
            type: "solid",
            color: "#B1B9C4",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
          dropShadow: {
            enabled: false,
            top: 0,
            left: 0,
            blur: 1,
            opacity: 0.4,
          },
        },
        tooltip: {
          enabled: false,
          formatter: undefined,
          offsetY: 0,
          style: {
            // fontSize: 0,
            // fontFamily: 0,
          },
        },
      },
      yaxis: {
        show: false,
        title: {
          text: undefined,
        },
      },
      fill: {
        opacity: 1,
        colors: [
          //previous color code comment by furkan
          // "#70c9c9",
          // "#427270",
          // "#70c9c9",
          // "#e6e6e6",
          // "#d87a80",

          //code addded furkan sheikh 26-12-2020
          "#165043",
          "#279078",
          "#35C0A0",
          "#E08E00",
          "#FFBB45",

        ],
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
        labels: {
          colors: "white",
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          fillColors: [
            //previous color comment 
            // "#70c9c9",
            // "#427270",
            // "#70c9c9",
            // "#e6e6e6",
            // "#d87a80",
           //code addded furkan sheikh 26-12-2020
            "#165043",
            "#279078",
            "#35C0A0",
            "#E08E00",
            "#FFBB45",
          ],
        },
      },
    };
  }

  loginhide = true;
  title = "dempapp";
  walletAddress: any;
  mmlogged = false;
  newsc = "0xf7A58C660245D6AC6B1367D84694b7Fa46C935B8";
  oldsc = "0xc813EA5e3b48BEbeedb796ab42A30C5599b01740";
  newabi = [
    {
      inputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        { indexed: true, internalType: "address", name: "", type: "address" },
        {
          indexed: false,
          internalType: "uint256",
          name: "_value",
          type: "uint256",
        },
      ],
      name: "AddedNewUser",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "tokenholder",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "previousOwner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "newOwner",
          type: "address",
        },
      ],
      name: "OwnershipTransferred",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    { payable: true, stateMutability: "payable", type: "fallback" },
    {
      constant: false,
      inputs: [],
      name: "addAddressExisting",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "_useraddress", type: "address" },
        { internalType: "uint256", name: "_value", type: "uint256" },
        { internalType: "uint256", name: "_txHashAddress", type: "uint256" },
        {
          internalType: "enum Staking.userState",
          name: "_userState",
          type: "uint8",
        },
        {
          internalType: "enum Staking.withdrawState",
          name: "_withdrawState",
          type: "uint8",
        },
        {
          internalType: "enum Staking.remainToken",
          name: "_remainToken",
          type: "uint8",
        },
      ],
      name: "addAddressNew",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "blockpeople",
      outputs: [{ internalType: "address", name: "_address", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "_useraddress", type: "address" },
      ],
      name: "blockuser",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { internalType: "address", name: "_useraddress", type: "address" },
      ],
      name: "chkUserInPreviousContract",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { internalType: "address", name: "_useraddress", type: "address" },
      ],
      name: "getFirstStakeDate",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "_address", type: "address" }],
      name: "getRemainTokenCount",
      outputs: [{ internalType: "uint256", name: "tokens", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { internalType: "uint256", name: "_tokenId", type: "uint256" },
        { internalType: "address", name: "_useraddress", type: "address" },
      ],
      name: "getStakeTokenById",
      outputs: [
        { internalType: "address", name: "", type: "address" },
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "uint256", name: "", type: "uint256" },
        { internalType: "uint256", name: "", type: "uint256" },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "_address", type: "address" }],
      name: "getUserState",
      outputs: [
        { internalType: "enum Staking.userState", name: "", type: "uint8" },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "_address", type: "address" }],
      name: "getWithdrawTokenCount",
      outputs: [{ internalType: "uint256", name: "tokens", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "owner",
      outputs: [{ internalType: "address", name: "", type: "address" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "", type: "address" }],
      name: "people",
      outputs: [
        { internalType: "uint256", name: "_id", type: "uint256" },
        { internalType: "address", name: "_address", type: "address" },
        { internalType: "uint256", name: "_value", type: "uint256" },
        { internalType: "uint256", name: "_txHashAddress", type: "uint256" },
        {
          internalType: "enum Staking.userState",
          name: "_userState",
          type: "uint8",
        },
        {
          internalType: "enum Staking.withdrawState",
          name: "_withdrawState",
          type: "uint8",
        },
        {
          internalType: "enum Staking.remainToken",
          name: "_remainToken",
          type: "uint8",
        },
        { internalType: "uint256", name: "_blocktimestamp", type: "uint256" },
        {
          internalType: "uint256",
          name: "_userStateBlocktimestamp",
          type: "uint256",
        },
        { internalType: "uint256", name: "_stakeCounter", type: "uint256" },
        {
          internalType: "uint256",
          name: "_withdrawTimestamp",
          type: "uint256",
        },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "peopleCount",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "_address", type: "address" }],
      name: "remainPenaltyClaimDate",
      outputs: [{ internalType: "uint256", name: "date", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ internalType: "uint256", name: "_amt", type: "uint256" }],
      name: "stake",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "uint256", name: "_amt", type: "uint256" },
        { internalType: "address", name: "_useraddress", type: "address" },
      ],
      name: "stakeForOther",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { internalType: "address", name: "_useraddress", type: "address" },
      ],
      name: "totalStaked",
      outputs: [
        { internalType: "uint256", name: "_totalStakes", type: "uint256" },
      ],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "_useraddress", type: "address" },
      ],
      name: "unblockuser",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "unstake",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "unstakeRequest",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        {
          internalType: "enum Staking.userState",
          name: "_userStates",
          type: "uint8",
        },
      ],
      name: "withdrawOrStake",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "tokenContract", type: "address" },
        { internalType: "uint256", name: "count", type: "uint256" },
      ],
      name: "withdrawOtherTokens",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [{ internalType: "uint256", name: "_tkns", type: "uint256" }],
      name: "withdrawOwnerNioxToken",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "withdrawRemainPenaltyToken",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "withdrawToken",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
  ];
  oldabi = this.newabi;
  nioxtkn = "0xc813EA5e3b48BEbeedb796ab42A30C5599b01740";
  nioxabi = [
    {
      inputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "constructor",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "owner",
          type: "address",
        },
        {
          indexed: true,
          internalType: "address",
          name: "spender",
          type: "address",
        },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Approval",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "MinterAdded",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "account",
          type: "address",
        },
      ],
      name: "MinterRemoved",
      type: "event",
    },
    {
      anonymous: false,
      inputs: [
        {
          indexed: true,
          internalType: "address",
          name: "from",
          type: "address",
        },
        { indexed: true, internalType: "address", name: "to", type: "address" },
        {
          indexed: false,
          internalType: "uint256",
          name: "value",
          type: "uint256",
        },
      ],
      name: "Transfer",
      type: "event",
    },
    {
      constant: false,
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "addMinter",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [
        { internalType: "address", name: "owner", type: "address" },
        { internalType: "address", name: "spender", type: "address" },
      ],
      name: "allowance",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "approve",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "balanceOf",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "decimals",
      outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "subtractedValue", type: "uint256" },
      ],
      name: "decreaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "spender", type: "address" },
        { internalType: "uint256", name: "addedValue", type: "uint256" },
      ],
      name: "increaseAllowance",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [{ internalType: "address", name: "account", type: "address" }],
      name: "isMinter",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "account", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "mint",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "name",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [],
      name: "renounceMinter",
      outputs: [],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "symbol",
      outputs: [{ internalType: "string", name: "", type: "string" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: true,
      inputs: [],
      name: "totalSupply",
      outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
      payable: false,
      stateMutability: "view",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transfer",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
    {
      constant: false,
      inputs: [
        { internalType: "address", name: "sender", type: "address" },
        { internalType: "address", name: "recipient", type: "address" },
        { internalType: "uint256", name: "amount", type: "uint256" },
      ],
      name: "transferFrom",
      outputs: [{ internalType: "bool", name: "", type: "bool" }],
      payable: false,
      stateMutability: "nonpayable",
      type: "function",
    },
  ];
  totalStaked = 0;
  showNewUserDiv = false;
  stakingDate = "DD-MM-YYYY";
  showConnectDiv = true;
  reward;
  showModal;
  content;
  name = new FormControl("");
  message;
  subdis = true;
  withdrawdate = true;
  ustatus: string = "Not Decided";
  userstakeamt = 0;
  oneagaintry = 1;
  chkstaker: boolean;
  href;
  teammember: boolean;
  tmaddress = ['0xf35310FDb7d319E5F9092576e3435E9B2307c37a',
    '0xcded1Ebe25236a04273F0Fe7675fEcf46108d9ef',
    '0xd5A74586d05E6c0C4b10FccC350598F71D593910',
    '0xca93D27Ff7616fEC076e138FeF1E2F3575f73eDc',
    '0x9f67f00a63f844ce9084E3db6662598E7BC8ee16',
    '0xE0922a4FAB679F00ebD21216e8788a65376047Dc',
    '0x8Bd96eEEe99c99ecaD40E31B67b3787aDDC43D25',
    '0x79632CE537c16A3395cE330Dc037aE09e08B0F18',
    '0xA8C04EB85069fE6387A311CBdcAa4d32646ae02c',
    '0x62d589bcaccaa636827c5e0209468080ff4577b1',
    '0x76FEd379D7A40ad819fB5d19171CAB9BB5C7B501',
    '0x76FEd379D7A40ad819fB5d19171CAB9BB5C7B501',
    '0x5B2412a16208967a91F6518b147d5c7D30E9cFfD',
    '0xc7203f0C82Dd6D5Abe3b0eB83285d4e1CC5cB296']
  showteammemberdiv: boolean = false;
  valueassigned;
  ngOnInit() {
    this.mmlogged = true;
  }
  async loginMetamask() {
    if (window.web3) {
      const web3 = new Web3(window.web3.currentProvider);
      var message = "Please sign this message to connect with NIOX Staking DApp"
      // var hash = web3.utils.sha3(message);
      var accounts = await web3.eth.getAccounts()
      if (accounts[0] !== undefined) {
        web3.eth.net.getId().then(
          async (response) => {
            if (response !== 1) {
              //alert("Select  mainnet only ");
              this.errorMsgModal = true;
              this.modalText = "Please select Ethereum Mainnet on Metamask and try again.";
            } else {
              console.log("ss accounts ==>", accounts[0]);
              this.errorMsgModal = true;
              this.modalText = this.modalTextOne;
              var signature = await web3.eth.personal.sign(message, accounts[0])
              console.log("signature ==>", signature);
              this.mmlogged = false;
              this.walletAddress = accounts[0];
              this.loginMsgModal = false;
              this.errorMsgModal = false;
              this.fetchUserDetails(this.walletAddress);
            }
          });
      } else {
        const scope = this;
        const web3 = new Web3(window.web3.currentProvider);
        // if (window.web3) {
        await window.ethereum.enable();
        console.log("Detect MetaMask has successfully connected.");
        web3.eth.net.getId().then(
          async (response) => {
            if (response !== 1) {
              //alert("Select  Mainnet only ");
              this.errorMsgModal = true;
              this.modalText = "Please select Ethereum Mainnet on Metamask and try again.";
            } else {
              var accounts = await web3.eth.getAccounts();
              // window['ethereum']
              //   .request({ method: "eth_accounts" })
              //   // web3.eth.getAccounts()
              //   .then(
              // async (response1) => {
              console.log("getaccount", accounts[0]);
              if (accounts[0] === undefined || accounts[0] === "") {
                console.log("error");
              } else {

                console.log(accounts[0]);
                console.log("accounts ==>", accounts[0]);
                this.errorMsgModal = true;
              this.modalText = this.modalTextOne;
                var signature = await web3.eth.personal.sign(message, accounts[0])
                console.log("signature ==>", signature);
                this.mmlogged = false;
                this.walletAddress = accounts[0];
                this.loginMsgModal = false;
                this.errorMsgModal = false;
                this.fetchUserDetails(this.walletAddress);
              }
              // },
              //   (response2) => {
              //     // this.modalText = "There was error while fetching your details";
              //     console.log("getaccount error", response2);
              //   }
              // );
            }
            console.log(response);
          },
          function error(response) {
            // handle our error
            this.loginMsgModal = false;
            this.errorMsgModal = false;
            this.modalText = "Metamask error";
            console.log("Error Thrown", response);
          }
        );
      }
    }
    else {
      //alert("Metamask is not installed, please try again after installing Metamask.");
      this.errorMsgModal = true;
      this.modalText = "Metamask is not installed, please try again after installing Metamask.";
    }
    // }


  }


  /**
  * Details : - function is used to close Modal popup
  * Created by - furkan sheikh
  * Modified Date 16-Nov-2020
 */
  closeModal() {
    this.errorMsgModal = false;

  }



  fetchUserDetails(walletAddress) {
    const scope = this;
    const web3 = new Web3(window.web3.currentProvider);
    const newContract = new web3.eth.Contract(this.newabi, this.newsc);
    console.log("new sc", this.newabi, this.newsc);
    const people = newContract.methods
      .people(walletAddress)
      .call()
      .then(async (res) => {
        console.log("ressssss new sc", res._address);
        // alert("called" + res);

        if (res._address === "0x0000000000000000000000000000000000000000") {
          // checking in previous sc
          const oldContract = new web3.eth.Contract(
            this.newabi, this.oldsc
          );
          console.log("old sc", this.newabi, this.newsc);
          const people1 = newContract.methods
            .people(walletAddress)
            .call()
            .then(async (res1) => {
              console.log("resssss old sc", res1)
              // if present in oldsc then show connect btn
              if (walletAddress === res1._address) {
                console.log("show connect btn");
                this.showbtn({
                  btn: "Connect with Staking Smart Contract",
                  title: "",
                  funct: "addExistingAddress()",
                });
                // to enable connect div
                this.showconnectbtn = true;
                // to disbale acc balance and staker div
                this.showConnectDiv = false
                // this.showConnectDiv = false;
              } else {
                
                this.showNewUserDiv = true;
                console.log("not in old and new sc OR new user");
              }
            });
        } else if (res._address === walletAddress) {
          console.log("resssssssssssssssssssss", res)
          this.setUserData(res);
        } else {
          this.showNewUserDiv = true;
          this.showbtn({
            btn: "New User",
            title: "",
            funct: "newUser()",
          });
          // if (this.oneagaintry === 1) {
          //   alert('called');
          //   this.oneagaintry = 0;
          //   this.fetchUserDetails(this.walletAddress);
          // }
        }
        console.log("people", res[1]);
      })
      .catch((e) => { alert("error" + e) })
  }

  showbtn(btndetails) {
    // console.log(btndetails);
  }
  async setUserData(people) {
    console.log("peopleeeeeeeeeeeeeee", people)
    if (people._userState == 1) {
      this.chkstaker = true;
      this.ustatus = "Staker";
      console.log("user is staker chk totak staked");
      const web3 = new Web3(window.web3.currentProvider);
      const newContract = new web3.eth.Contract(this.newabi, this.newsc);
      const tknContract = new web3.eth.Contract(this.nioxabi, this.nioxtkn);

      newContract.methods
        .getFirstStakeDate(people[1])
        .call()
        .then((res) => {
          console.log("date", res, new Date(res * 1000));
          this.stakingDate = new Date(res * 1000).toISOString();
          if (Math.floor(new Date().getTime()) > res * 1000 + 15778458000) {
            this.withdrawdate = false;
          }
        });
      const people2 = newContract.methods
        .totalStaked(people[1])
        .call()
        .then((res) => {
          this.totalStaked = res / 10000;
          this.calculate('test');
          console.log("total staked", res / 10000);
          tknContract.methods
            .balanceOf(people[1])
            .call()
            .then((res1) => {
              this.chartOptions2 = {
                series: [
                  {
                    name: "Balance in Wallet",
                    data: [res1 / 10000],
                  },
                  {
                    name: "Staked Amount",
                    data: [this.totalStaked],
                  },
                ],
                chart: {
                  type: "bar",
                  height: 200,
                  stacked: true,
                  fontFamily: 'Work Sans, sans-serif',
                  stackType: '100%',
                  toolbar: {
                    show: false
                  },
                },
                tooltip: {
                  enabled: true,
                  marker: {
                    show: false,
                  },
                  custom: ({ series, seriesIndex, dataPointIndex, w })=> {
                    return (
                      '<div style="color: black " class="arrow_box">'
                      + this.numberWithCommas(series[seriesIndex][dataPointIndex])+
                      "</div>"
                    );
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
                stroke: {
                  width: 1,
                  colors: ["#fff"],
                },
                xaxis: {
                  type: "category",
                  categories: [],
                  labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: false,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                      colors: [
                        "white",
                        "white",
                        "white",
                        "white",
                        "white",
                        "white",
                      ],
                      fontSize: "12px",
                      fontFamily: 'Work Sans, sans-serif',
                      fontWeight: 400,
                      cssClass: "apexcharts-xaxis-label",
                    },
                    offsetX: 0,
                    offsetY: 0,
                    format: undefined,
                    formatter: undefined,
                    datetimeUTC: true,
                    datetimeFormatter: {
                      year: "yyyy",
                      month: "MMM 'yy",
                      day: "dd MMM",
                      hour: "HH:mm",
                    },
                  },
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    // height: 1,
                    // width: "100%",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    height: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  tickAmount: undefined,
                  tickPlacement: "between",
                  min: undefined,
                  max: undefined,
                  range: undefined,
                  floating: false,
                  position: "bottom",
                  title: {
                    text: undefined,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: undefined,
                      fontSize: "12px",
                      fontFamily: 'Work Sans, sans-serif',
                      fontWeight: 600,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  crosshairs: {
                    show: true,
                    width: 1,
                    position: "back",
                    opacity: 0.9,
                    stroke: {
                      color: "#b6b6b6",
                      width: 0,
                      dashArray: 0,
                    },
                    fill: {
                      type: "solid",
                      color: "#B1B9C4",
                      gradient: {
                        colorFrom: "#D8E3F0",
                        colorTo: "#BED1E6",
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                      },
                    },
                    dropShadow: {
                      enabled: false,
                      top: 0,
                      left: 0,
                      blur: 1,
                      opacity: 0.4,
                    },
                  },
                  tooltip: {
                    enabled: false,
                    formatter: undefined,
                    offsetY: 0,
                    style: {
                      // fontSize: 0,
                      // fontFamily: 0,
                    },
                  },
                },
                yaxis: {
                  show: false,
                  title: {
                    text: undefined,
                  },
                },
                fill: {
                  opacity: 1,
                  colors: [
                    "#70c9c9",
                    "#427270",
                    "#70c9c9",
                    "#e6e6e6",
                    "#d87a80",
                  ],
                },
                legend: {
                  position: "top",
                  horizontalAlign: "left",
                  offsetX: 40,
                  labels: {
                    colors: "white",
                    useSeriesColors: false,
                  },
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: "#fff",
                    fillColors: [
                      "#70c9c9",
                      "#427270",
                      "#70c9c9",
                      "#e6e6e6",
                      "#d87a80",
                    ],
                  },
                },
              };
            });
        });
      this.calculate('sad');
    } else {
      let abc = await this.tmaddress.forEach((val, index) => {
        // console.log("val index", val, index)
        //check teammeber with user state 2
        if (people[1] == val) {
          console.log('user in tm address list')
          this.teammember = true;
          if (people._userState == 2 && people._value >= 3000000000) {
            this.showteammemberdiv = true;
            this.valueassigned = people._value / 10000;
            console.log("Akash team member")
          }
        }
      })


      this.chkstaker = false;
      const web3 = new Web3(window.web3.currentProvider);
      const newContract = new web3.eth.Contract(this.newabi, this.newsc);
      const tknContract = new web3.eth.Contract(this.nioxabi, this.nioxtkn);
      const people2 = newContract.methods
        .totalStaked(people[1])
        .call()
        .then((res) => {
          this.totalStaked = res / 10000;
          this.calculate('test');
          console.log("total staked", res / 10000);
          tknContract.methods
            .balanceOf(people[1])
            .call()
            .then((res1) => {
              this.chartOptions2 = {
                series: [
                  {
                    name: "Balance in Wallet",
                    data: [res1 / 10000],
                  },
                  {
                    name: "Staked Amount",
                    data: [this.totalStaked],
                  },
                ],
                chart: {
                  type: "bar",
                  height: 200,
                  stacked: true,
                  fontFamily: 'Work Sans, sans-serif',
                  stackType: '100%',
                  toolbar: {
                    show: false
                  },
                },
                tooltip: {
                  enabled: true,
                  marker: {
                    show: false,
                  },
                  custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                    return (
                      '<div style="color: black " class="arrow_box">'
                      + this.numberWithCommas(series[seriesIndex][dataPointIndex])+
                      "</div>"
                    );
                  }
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
                stroke: {
                  width: 1,
                  colors: ["#fff"],
                },
                xaxis: {
                  type: "category",
                  categories: [],
                  labels: {
                    show: true,
                    rotate: -45,
                    rotateAlways: false,
                    hideOverlappingLabels: true,
                    showDuplicates: false,
                    trim: false,
                    minHeight: undefined,
                    maxHeight: 120,
                    style: {
                      colors: [
                        "white",
                        "white",
                        "white",
                        "white",
                        "white",
                        "white",
                      ],
                      fontSize: "12px",
                      fontFamily: 'Work Sans, sans-serif',
                      fontWeight: 400,
                      cssClass: "apexcharts-xaxis-label",
                    },
                    offsetX: 0,
                    offsetY: 0,
                    format: undefined,
                    formatter: undefined,
                    datetimeUTC: true,
                    datetimeFormatter: {
                      year: "yyyy",
                      month: "MMM 'yy",
                      day: "dd MMM",
                      hour: "HH:mm",
                    },
                  },
                  axisBorder: {
                    show: true,
                    color: "#78909C",
                    // height: 1,
                    // width: "100%",
                    offsetX: 0,
                    offsetY: 0,
                  },
                  axisTicks: {
                    show: true,
                    borderType: "solid",
                    color: "#78909C",
                    height: 6,
                    offsetX: 0,
                    offsetY: 0,
                  },
                  tickAmount: undefined,
                  tickPlacement: "between",
                  min: undefined,
                  max: undefined,
                  range: undefined,
                  floating: false,
                  position: "bottom",
                  title: {
                    text: undefined,
                    offsetX: 0,
                    offsetY: 0,
                    style: {
                      color: undefined,
                      fontSize: "12px",
                      fontFamily: 'Work Sans, sans-serif',
                      fontWeight: 600,
                      cssClass: "apexcharts-xaxis-title",
                    },
                  },
                  crosshairs: {
                    show: true,
                    width: 1,
                    position: "back",
                    opacity: 0.9,
                    stroke: {
                      color: "#b6b6b6",
                      width: 0,
                      dashArray: 0,
                    },
                    fill: {
                      type: "solid",
                      color: "#B1B9C4",
                      gradient: {
                        colorFrom: "#D8E3F0",
                        colorTo: "#BED1E6",
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                      },
                    },
                    dropShadow: {
                      enabled: false,
                      top: 0,
                      left: 0,
                      blur: 1,
                      opacity: 0.4,
                    },
                  },
                  tooltip: {
                    enabled: false,
                    formatter: undefined,
                    offsetY: 0,
                    style: {
                      // fontSize: 0,
                      // fontFamily: 0,
                    },
                  },
                },
                yaxis: {
                  show: false,
                  title: {
                    text: undefined,
                  },
                },
                fill: {
                  opacity: 1,
                  colors: [
                    "#70c9c9",
                    "#427270",
                    "#70c9c9",
                    "#e6e6e6",
                    "#d87a80",
                  ],
                },
                legend: {
                  position: "top",
                  horizontalAlign: "left",
                  offsetX: 40,
                  labels: {
                    colors: "white",
                    useSeriesColors: false,
                  },
                  markers: {
                    width: 12,
                    height: 12,
                    strokeWidth: 0,
                    strokeColor: "#fff",
                    fillColors: [
                      "#70c9c9",
                      "#427270",
                      "#70c9c9",
                      "#e6e6e6",
                      "#d87a80",
                    ],
                  },
                },
              };
            });
        });
      this.chkstaker = false;
      this.ustatus = "Not a Staker";
      console.log("new user", people[4]);
      // not a staker
    }
  }
  oldToNew() {
    const web3 = new Web3(window.web3.currentProvider);
    const newContract = new web3.eth.Contract(this.newabi, this.newsc);
    newContract.methods
      .addAddressExisting()
      .send({ from: this.walletAddress })
      .then((res) => { })
      .catch((e) => {
        // location.reload();
      });
    console.log(this.walletAddress);
  }
  chkSubscription(walletAddress) {
    const scope = this;
    localStorage.setItem("useraddress", walletAddress);
    const web3 = new Web3(window.web3.currentProvider);
    const minABI = [
      {
        inputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "spender",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Approval",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "MinterAdded",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "account",
            type: "address",
          },
        ],
        name: "MinterRemoved",
        type: "event",
      },
      {
        anonymous: false,
        inputs: [
          {
            indexed: true,
            internalType: "address",
            name: "from",
            type: "address",
          },
          {
            indexed: true,
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            indexed: false,
            internalType: "uint256",
            name: "value",
            type: "uint256",
          },
        ],
        name: "Transfer",
        type: "event",
      },
      {
        constant: false,
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "addMinter",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [
          { internalType: "address", name: "owner", type: "address" },
          { internalType: "address", name: "spender", type: "address" },
        ],
        name: "allowance",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "approve",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "balanceOf",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "decimals",
        outputs: [{ internalType: "uint8", name: "", type: "uint8" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "subtractedValue", type: "uint256" },
        ],
        name: "decreaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "spender", type: "address" },
          { internalType: "uint256", name: "addedValue", type: "uint256" },
        ],
        name: "increaseAllowance",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [{ internalType: "address", name: "account", type: "address" }],
        name: "isMinter",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "account", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "mint",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "name",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [],
        name: "renounceMinter",
        outputs: [],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "symbol",
        outputs: [{ internalType: "string", name: "", type: "string" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: true,
        inputs: [],
        name: "totalSupply",
        outputs: [{ internalType: "uint256", name: "", type: "uint256" }],
        payable: false,
        stateMutability: "view",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "recipient", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transfer",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        constant: false,
        inputs: [
          { internalType: "address", name: "sender", type: "address" },
          { internalType: "address", name: "recipient", type: "address" },
          { internalType: "uint256", name: "amount", type: "uint256" },
        ],
        name: "transferFrom",
        outputs: [{ internalType: "bool", name: "", type: "bool" }],
        payable: false,
        stateMutability: "nonpayable",
        type: "function",
      },
    ];
    const tokenAddress = "0xc813EA5e3b48BEbeedb796ab42A30C5599b01740"; // niox token address mainnet
    // const contract = new web3.eth.contract(minABI).at(tokenAddress);
    const myContract = new web3.eth.Contract(minABI, tokenAddress);
    // Call balanceOf function
    const balance = myContract.methods
      .balanceOf(walletAddress)
      .call()
      .then((res) => {
        console.log("balance", res);
        if (res > 0) {
          this.loginhide = false;
          this.router.navigateByUrl("/dashboard");
        } else {
          this.loginhide = false;
          this.router.navigateByUrl("/dashboard");
        }
      });
  }
  calculate(txt) {
    // console.log("callllllllllllllllllllllllllllllllllllllll", txt, this.totalStaked)
    let reward_percent = (this.totalStaked * 100) / 82483434;
    // this.reward = ((15000000 * reward_percent) / 1000).toFixed(4);
    if (this.totalStaked >= 300000) {
      this.reward = ((this.totalStaked / 82483434) * 15000000).toFixed(4);
    }else{
      this.reward = 0;
    }


  }
  unstake() { }
  show() {
    this.showModal = true; // Show-Hide Modal Check
    this.content = "This is content!!"; // Dynamic Data
    this.title = "This is title!!"; // Dynamic Data
  }
  //Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }
  async addStake() {
    // console.log(this.name.value);
    // console.log(this.name);
    const scope = this;

    if (this.totalStaked >= 300000) {
      console.log("staker");
      const web3 = new Web3(window.web3.currentProvider);
      const nioxtknContract = new web3.eth.Contract(this.nioxabi, this.nioxtkn);
      const stakingContract = new web3.eth.Contract(this.newabi, this.newsc);
      await nioxtknContract.methods
        .allowance(this.walletAddress, '0xf7A58C660245D6AC6B1367D84694b7Fa46C935B8')
        .call()
        .then(async (res) => {
          // already approve
          if (parseFloat(res) / 10000  >= this.name.value) {
            await stakingContract.methods
              .stake(this.name.value + "0000")
              .send({ from: this.walletAddress })
              .then((res) => {
                this.hide();
              })
              .catch((e) => {
                this.hide();
                //alert("Metamask Rejected" + e.message);
                this.errorMsgModal = true;
                this.modalText = "Metamask Rejected" + e.message;
                // location.reload();
              });
          } else {
            // approve first then stake
            console.log("nioxtknContract", nioxtknContract);
            await nioxtknContract.methods
              .approve(this.newsc, this.name.value + "0000")
              .send({ from: this.walletAddress })
              .then(async (res) => {
                await stakingContract.methods
                  .stake(this.name.value + "0000")
                  .send({ from: this.walletAddress })
                  .then((res) => {
                    this.hide();
                  })
                  .catch(function (e) {
                    this.hide();
                    //alert("Metamask Rejected" + e.message);
                    this.errorMsgModal = true;
                    this.modalText = "Metamask Rejected" + e.message;

                    // location.reload();
                  });
              })
              .catch((e) => {
                this.hide();
                this.errorMsgModal = true;
                this.modalText = "Metamask Rejected" + e.message;
                // location.reload();
              });
          }
        })
        .catch((e) => {
          console.log(e);
          this.hide();
          this.errorMsgModal = true;
          this.modalText = "Metamask Rejected" + e.message;
          // location.reload();
        });
    } else {
      const web3 = new Web3(window.web3.currentProvider);
      const nioxtknContract = new web3.eth.Contract(this.nioxabi, this.nioxtkn);
      const stakingContract = new web3.eth.Contract(this.newabi, this.newsc);
      if (this.name.value >= 300000) {
        console.log("first stake", this.nioxabi, this.nioxtkn, this.walletAddress, "0xf7A58C660245D6AC6B1367D84694b7Fa46C935B8");
        await nioxtknContract.methods
          .allowance(this.walletAddress, '0xf7A58C660245D6AC6B1367D84694b7Fa46C935B8')
          .call()
          .then(async (res) => {
            // already approve
            // alert("res : " + res)
            if (parseFloat(res) / 10000  >= this.name.value) {
              await stakingContract.methods
                .stake(this.name.value + "0000")
                .send({ from: this.walletAddress })
                .then((res) => {
                  this.hide();
                })
                .catch((e) => {
                  this.hide();
                  //alert("Metamask Rejected" + e.message);
                  this.errorMsgModal = true;
                  this.modalText = "Metamask Rejected" + e.message;

                  // location.reload();
                });
            } else {
              // approve first then stake
              await nioxtknContract.methods
                .approve(this.newsc, this.name.value + "0000")
                .send({ from: this.walletAddress })
                .then(async (res) => {
                  await stakingContract.methods
                    .stake(this.name.value + "0000")
                    .send({ from: this.walletAddress })
                    .then(function (res) {
                      this.hide();
                    })
                    .catch((e) => {
                      this.hide();
                      //alert("Metamask Rejected" + e.message);
                      this.errorMsgModal = true;
                      this.modalText = "Metamask Rejected" + e.message;
                      // location.reload();
                    });
                });
            }
          })
          .catch(function (e) {
            // location.reload();
          });
      } else {
        this.message = "Minimum Staking amount is 300000";
        console.log("less");
      }
    }
  }

  unStake() {
    const web3 = new Web3(window.web3.currentProvider);
    const newContract = new web3.eth.Contract(this.newabi, this.newsc);
    newContract.methods
      .people(this.walletAddress)
      .call()
      .then((res) => {
        newContract.methods
          .getStakeTokenById(res._stakeCounter, this.walletAddress)
          .call()
          .then((res1) => {
            console.log("stake date", res1);
            // Math.floor(new Date().getTime()) > res * 1000 + 15778458000
            if (
              res1[3] != 0 &&
              Math.floor(new Date().getTime()) < res1[3] * 1000 + 1814400
            ) {
              const web3 = new Web3(window.web3.currentProvider

              );
              const newContract = new web3.eth.Contract(
                this.newabi,
                this.newsc
              );
              newContract.methods
                .unstake()
                .send({ from: this.walletAddress })
                .then(function (res) { })
                .catch(function (e) {
                  // location.reload();
                });
            } else {
              const web3 = new Web3(window.web3.currentProvider);
              const newContract = new web3.eth.Contract(
                this.newabi,
                this.newsc
              );
              newContract.methods
                .unstakeRequest()
                .send({ from: this.walletAddress })
                .then(function (res) { })
                .catch(function (e) {
                  // location.reload();
                });
            }
          });
      });
  }

  ndtostake() {
    const web3 = new Web3(window.web3.currentProvider);
    const newContract = new web3.eth.Contract(
      this.newabi,
      this.newsc
    );
    newContract.methods
      .withdrawOrStake("1")
      .send({ from: this.walletAddress })
      .then((res) => {
        this.errorMsgModal = true;
        this.modalText = "Transaction sent successfully";
      })
      .catch(function (e) {
        this.errorMsgModal = true;
        this.modalText = "Metamask Rejected" + e.message;
        // location.reload();
      });
  }
  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
async walletconnect() {

  const bridge = "https://bridge.walletconnect.org";
  const connector = new wcc({ bridge, qrcodeModal: QRCodeModal });
  const message = "Please sign this message to connect with NIOX Staking Dapp"
  if (!connector.connected) {
    // create new session
    console.log("new connection request sent");
    let con = await connector.createSession();
    connector.on("connect", (error, payload) => {
      console.log(`connector.on("connect")`, error, payload.params[0].accounts[0]);
      this.walletAddress = payload.params[0].accounts[0];
      const msgParams = [
        message,                                                // Required
        this.walletAddress,                             // Required
      ];
      // enable loader
      this.errorMsgModal = true;
      this.modalText = this.modalTextWallet;
      connector
        .signPersonalMessage(msgParams)
        .then((result) => {
          // disabled loader
          // Returns signature.
          this.loginMsgModal = false;
          console.log("Returns signature", result)
          this.mmlogged = false;
          this.walletAddress = payload.params[0].accounts[0];
          this.errorMsgModal = false;
          this.fetchUserDetails(this.walletAddress);
        })
        .catch(error => {
          // disbaled loader
          // Error returned when rejected
          this.loginMsgModal = false;
          this.errorMsgModal = false;
          console.error("Error returned when rejected", error);
        })

    });
  }
  else {
    console.log("already connected", connector.accounts[0]);
    this.walletAddress = connector.accounts[0];
    const msgParams = [
      message,                                                // Required
      this.walletAddress,                             // Required
    ];
    // enable loader
    this.errorMsgModal = true;
    this.modalText = this.modalTextWallet;
    connector
      .signPersonalMessage(msgParams)
      .then((result) => {
        // disbaled loader
        // Returns signature.
        // this.LoginUser(this.username);
        this.mmlogged = false;
        this.loginMsgModal = false;
        this.errorMsgModal = false;
        this.fetchUserDetails(this.walletAddress);
        console.log("Returns signature", result)
        console.log("Returns signature", result)
      })
      .catch(error => {
        // disbaled loader
        // Error returned when rejected
        this.errorMsgModal = false;
        this.loginMsgModal = false;
        console.error("Error returned when rejected", error);
      })
  }
}
openpopup() {
  this.loginMsgModal = true;
  // alert("akash")
}
closeModallogin() {
  this.loginMsgModal = false;
}

closeModalLoader() {
    this.errorMsgModal = false;
    // this.forgotPassModal = false;
    this.isLoadingPopUp = false;
    this.isLoadingPopUpWalletPopUp = false;
  }
}
