import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  walletAddress: any;

  constructor() { }

  ngOnInit() {
   this.walletAddress = localStorage.getItem('useraddress');
  }

}
